<template>
  <va-card class="mt-4">
    <va-card-content>
        <va-input
        class="mx-3 mt-2"
        v-model="playgrounds_count"
        @keypress="playgrounds_counter"
        :label="$t('playgrounds_counts')"
        type="number"
        required=true
        />
        <form  v-if="playgrounds_count" class="mt-4" @submit.prevent="addNewChampionship">
            <va-card stripe stripe-color="primary">
              <card-title class="mt-5 pt-4"><h1 class="p-4">{{ $t('championship_playgrounds') }}</h1></card-title>
                  <va-card-content class="playgrounds-row justify--space-evenly">
                    <div v-for="(playground,index) in playgrounds" :key="playground">
                      <div class="avatar-container mt-2 mb-4">
                        <va-file-upload file-types=".jpg,.png" v-model="playground.img" @update:modelValue="setImage(index)">asdasdas</va-file-upload>
                        <va-avatar size="large" :src='playground.imgPreview'  font-size="30px" class=""></va-avatar>
                      </div>
                      <div class="mt5">
                          <div class="md12">
                            <va-input
                              class="mb-4 mx-3"
                              v-model="playground.name"
                              :label="$t('playground_name')"
                              required=true
                            />
                            <va-input
                              class="mb-4 mx-3"
                              v-model="playground.type"
                              :label="$t('playground_type')"
                              required=true
                            />
                            <va-input
                              class="mb-4 mx-3"
                              v-model="playground.location"
                              :label="$t('playground_location')"
                              required=true
                            />
                          </div>
                      </div>
                    </div>
                    <va-divider inset />
                          <va-card-title>Ref</va-card-title>       
                          <div style="margin-top:10px;max-width: 300px;">
                              <label for="#referees">{{ $t('menu.referees') }}</label>
                              <va-select
                                  id="referees"
                                  class="mb-4"
                                  :options="referees"
                                  v-model="selectedReferees"
                                  :value-by="(referee) => referee.id" 
                                  :text-by="(referee) => referee.name"
                                  :track-by="(referee) => referee.id"
                                  :loading='loading'
                                  @update:modelValue="check(selectedReferees)"
                                  :rules="[selectedReferees => (Array.isArray(selectedReferees) && selectedReferees.length >= 3) || isRequired ]"
                                  clearable
                                  :color="refColor"
                                  multiple
                                  searchable
                                  required
                                  
                              >
                              <template #append>
                                  <va-icon v-if="refErrors=''"
                                  name="check"
                                  color="#039303"
                                  />
                              </template>
                              </va-select>
                              <p class="errors">{{ refErrors }}</p>
                              <va-chip
                                  v-for="chip in selectedReferees"
                                  :key="chip"
                                  :color="refColor"
                                  size="small"
                                  class="mr-2 my-2"
                                  closeable
                                  @update:modelValue="deleteChip(chip)"
                                  >
                                  {{ chip.name }}
                              </va-chip>
                          </div>   
                  </va-card-content>
                  <div class="card_footer">
                            <va-button :rounded="false" flat class="mr-4" @click="setSelectedTab(2)">
                              <va-icon  class="material-icons">{{prevArrow}}</va-icon>
                              {{$t('prev')}}
                            </va-button>
                            <va-button :rounded="false" class="mr-4" type="submit" :disabled="checkForm">
                              {{$t('finish')}}
                              <va-icon  class="material-icons">{{arrow}}</va-icon>
                            </va-button>
                  </div>
            </va-card> 
        </form>
    </va-card-content>
  </va-card>

</template>

<script>
import { gql, GraphQLClient, request } from 'graphql-request'
import { GET_REFEREES } from '@/graphql/queries/users'
  export default {
      name:'add-champ-step-three',
      data(){
          return{
              playgrounds_count:null,
              playgrounds: [],
              referees: [],
              selectedReferees :[],
              refColor:'',
              loading: true
          }
      },
      async created(){
        const response = await request(this.$store.state.appUrl,GET_REFEREES)
        this.loading = false
        this.referees = response.referees
      },
      computed:{
        prevArrow(){
          return this.$i18n.locale=='en' || this.$i18n.locale=='gb'?'chevron_left':'chevron_right'
        },
        playgrounds_counter(){
          this.playgrounds=[]
          for(let i=0;i<this.playgrounds_count;i++){
                this.playgrounds[i]={img:'',name:'',type:'',location:'',imgPreview:''}
         }
         return this.playgrounds_count
        },
        checkForm(){
            for(let i=0;i<this.playgrounds_count;i++){  
                if(this.playgrounds[i].img == "" || this.playgrounds[i].name == "" || this.playgrounds[i].type == "" || this.playgrounds[i].location == "")
                    return false;   
            }
            if(this.selectedReferees.length<2) return false
        }
      },
      methods: {
          setImage(index){
            if (this.playgrounds[index].img.length == 0) this.playgrounds[index].imgPreview=null
            this.$store.dispatch('encodeImageFileAsURL',{img:this.playgrounds[index].img,self:this,index})
          },
          async addNewChampionship() {
            this.selectedReferees=this.selectedReferees.map((referee)=>{
              return referee.id
            })
            this.playgrounds=this.playgrounds.map(playground=>{
              return {img:playground.img[0],name:playground.name, type:playground.type, location:playground.location}
            })
              this.$store.dispatch('setPlaygroundsReferees', {playgrounds:this.playgrounds, referees:this.selectedReferees})
              
              //GraphQL Mutation
              const graphQLClient = new GraphQLClient(this.$store.state.appUrl, {
                headers: {
                    // authorization: 'Bearer MY_TOKEN',
                },
                })
                const variables = {
                    "creator_id": this.newChampionship.creator_id,
                    "name": this.newChampionship.name,
                    "logo": this.newChampionship.logo[0],
                    "teams_count": Number(this.newChampionship.teams_count),
                    "team_players_count": Number(this.newChampionship.team_players_count),
                    "suggested_start_time": this.newChampionship.suggested_start_time,
                    "champ_type_id": Number(this.newChampionship.type_id),
                    "fees": Number(this.newChampionship.fees),
                    "playgrounds":this.newChampionship.playgrounds,
                    "referees":this.newChampionship.referees,
                    "teams":this.newChampionship.teams
                }
                const mutation = gql`
                 mutation($creator_id: Int, $name: String, $logo: Upload,
                  $fees:Int 
                  $teams_count: Int, $team_players_count: Int,$suggested_start_time: String,
                  $champ_type_id: Int, $playgrounds : [playgrounds],$referees: [Int] , $teams : [newChampTeams] )
                {
                  newChampionship(newChampionshipInput:{
                    creator_id:$creator_id,
                    name:$name,logo:$logo,
                    teams_count:$teams_count,
                    team_players_count:$team_players_count ,
                    suggested_start_time:$suggested_start_time, 
                    champ_type_id:$champ_type_id,
                    fees: $fees
                    playgrounds:$playgrounds, referees: $referees, teams: $teams })
                      {
                        id,
                        status,
                        msg
                      }
              }`

                const response = await  graphQLClient.request(mutation,variables)
                await this.$swal.fire({
                        text:this.$t('created_champ_text'),
                        icon:'success',
                        confirmButtonColor: '#039303',
                        confirmButtonText: this.$t('ok'),
                        })
                this.$router.push({name:'upcoming'});
                console.log(response.champs, response)

          },
          check(selectedReferees){
                if(Object.values(selectedReferees).length<3){
                    this.refErrors=this.$t('errors.referees_count')
                    this.refColor="#e42222"
                }
                else{
                    this.refErrors=""
                    this.refColor="#039303"
                }
          },
          deleteChip (chip) {
              this.selectedReferees = this.selectedReferees.filter((v) => v !== chip)
              if(this.selectedReferees.length<3){
                  this.refErrors=this.$t('errors.referees_count')
                  this.color="#e42222"

              }
              else{
                  this.refErrors=""
                  this.color="#039303"

              }
          },
      },
      inject: ['newChampionship','setSelectedTab'],
  }
</script>

<style scoped>
    .md6{
        display: flex;
        align-items: baseline;
    }
    .px-2{
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .p-4{
      padding:1.5rem !important;
    }
    .avatar-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
    }
    .playgrounds-row{
        display: flex;
        flex-wrap: wrap;
    }
    .card_footer{
        display: flex;
        justify-content: flex-end;
    }
    .errors{
        margin-top: -25px;
        padding-left: 10px;
        color: #e42222;
    }
</style>