<template>
  <form class="mt-4">
    <va-card stripe stripe-color="primary">
      <va-card-content v-if="selectedTeams" class="teams-row justify--space-evenly">
        <div v-for="(team, index) in selectedTeams" :key="team">
          <va-switch
            v-model="team.newTeam"
            true-inner-label="New"
            false-inner-label="Exist"
            color="success"
            class="mr-4 mb-4"
          />
          <div v-if="team.newTeam" class="mt5">
            <div class="md12">
              <va-input
                class="mb-4 mx-3"
                :value="index"
                v-model="selectedTeams[index].name"
                :label="$t('name')"
                required="true"
              />
            </div>
            <div class="md12">
              <va-select
                class="mb-4 px-2"
                :options="getAvailableManagers"
                :label="$t('manager_name')"
                v-model="selectedTeams[index].manager_id"
                :value-by="(manager) => manager.id"
                :text-by="(manager) => manager.name"
                :track-by="(manager) => manager.id"
                :loading="loading"
                searchable
                clearable
              >
              </va-select>
            </div>
            <va-divider inset />
          </div>
          <div v-else>
            <div class="md12">
              <va-select
                class="mb-4 px-2"
                :options="getAvailableTeams"
                :label="$t('menu.teams')"
                v-model="selectedTeams[index]"
                :value-by="(team)=> team"
                :text-by="(team) => team.name"
                :track-by="(team) => team.id"
                :loading="loading"
                searchable
                clearable
              >
              </va-select>
            </div>
          </div>
        </div>
      </va-card-content>
      <div class="card_footer p-4">
        <va-button
          :rounded="false"
          flat
          class="mr-4"
          type="button"
          @click="setSelectedTab(1)"
        >
          <va-icon class="material-icons">{{ prevArrow }}</va-icon>
          {{ $t("prev") }}
        </va-button>
        <va-button
          :rounded="false"
          class="mr-4"
          type="button"
          @click.prevent="setTeams"
          :disabled="checkForm"
        >
          {{ $t("next") }}
          <va-icon class="material-icons">{{ nextArrow }}</va-icon>
        </va-button>
      </div>
    </va-card>
  </form>
</template>

<script>
import { request, gql } from "graphql-request";
import { GET_ALL_USERS } from "@/graphql/queries/users";
export default {
  name: "add-champ-step-two",
  data() {
    return {
      allTeams: [],
      availableTeams: [],
      selectedTeams: [],
      allManagers: [],
      availableManagers: [],
      selectedManagers: [],
      loading: true,
    };
  },
  async created() {
    for (let i = 0; i < this.newChampionship?.teams_count; i++) {
      this.selectedTeams[i] = { name: "", manager_id: null, newTeam: false };
    }
    try {
      const GET_ALL_TEAMS = gql`
        query {
          Teams {
            name
            id
          }
        }
      `;
      const teamsResponse = await request(
        this.$store.state.appUrl,
        GET_ALL_TEAMS
      );

      this.allTeams = teamsResponse.Teams;
      for(let i = 0 ; i > this.allTeams.length ; i++){
        this.allTeams[i].newTeam = false
      }
      const response =  await request(this.$store.state.appUrl, GET_ALL_USERS);
      this.loading = false;
      this.allManagers = response.Users;
      console.log(this.allManagers,this.getAvailableManagers);
    } catch (error) {
      this.$swal.fire({
        icon: "info",
        title: this.$("errors.network"),
        text: "Try Again in a minute",
        confirmButtonColor: "#023b81",
      });
    }
  },
  methods: {
    setTeams() {
      this.$store.dispatch("setNewchampionshipTeams", this.selectedTeams);
      // console.log(this.$store.state.newChampionship.teams);
      this.setSelectedTab(3);
    },
    // selectTeam(team, index) {

    //   var found = this.preids.filter(x => x.id == index);
    //   if(found.length == 0){
    //       this.selectTeamLoop()
    //       this.preids.push({id: index,team: team.id})
    //   }else{
    //       this.selectTeamLoop();
    //       var i = this.preids.findIndex(x=> x.id == found[0].id);
    //       var data2 = this.constTeams.filter(x=> x.id == this.preids[i].team);
    //       this.preids[i].team = team.id;
    //       this.availableTeams.unshift(data2[0]);
    //  }
    // },
    // selectTeamLoop(){
    //     for (var i = 0; i < this.teams.length; i++) {
    //     this.availableTeams = this.availableTeams.filter((team) => team.id != this.teams[i].id);
    //   }
    // }
  },
  computed: {
    nextArrow() {
      return this.$i18n.locale == "en" || this.$i18n.locale == "gb"
        ? "chevron_right"
        : "chevron_left";
    },
    prevArrow() {
      return this.$i18n.locale == "en" || this.$i18n.locale == "gb"
        ? "chevron_left"
        : "chevron_right";
    },
    checkForm() {
      for (let i = 0; i < this.newChampionship?.teams_count; i++) {
        if (
          (this.selectedTeams[i].newTeam == true &&
            (this.selectedTeams[i].name == "" ||
              this.selectedTeams[i].manager_id == null ||
              this.selectedTeams[i].manager_id == "")) ||
          (this.selectedTeams[i].newTeam == false &&
            (this.selectedTeams[i].id == null || this.selectedTeams[i].id == ""))
        )
          return true;
      }
    },
    getAvailableTeams(){
        console.log(this.selectedTeams);
        return this.availableTeams = this.allTeams.filter(({ id: id1 }) => !this.selectedTeams.some((team) => team.id === id1))
    },
    getAvailableManagers(){
        return this.availableManagers = this.allManagers.filter(({ id: id1 }) => !this.selectedTeams.some(({ manager_id: id2 }) => id2 === id1))
    }
  },
  inject: ["setSelectedTab", "newChampionship"],
};
</script>

<style>
.teams-row {
  display: flex;
  flex-wrap: wrap;
}
.va-option-list__list{
  direction: ltr;
}
</style>
