<template>
  <form class="mt-4">
    <va-card stripe stripe-color="primary">
      <va-card-content>
        <div class="avatar-container mt-2 mb-4 pa-4">
          <!-- :style="[logoError?{'border':'solid 1px red'}:{'border':''}]" -->
          <va-file-upload
            type="single"
            v-model="newChampionship.logo"
            :rules="[(v) => v.length > 0]"
            :error-messages="requiredMessage"
            @update:modelValue="previewFile"
          />
          <img style="width: 20%" id="logo" />
        </div>
        <div class="mt5">
          <form>
            <div class="md12" v-if="users">
              <va-select
                class="mb-4 px-2"
                :options="users"
                :label="$t('organizer')"
                v-model="newChampionship.creator_id"
                :value-by="(user) => user?.id"
                :text-by="(user) => user?.name"
                :track-by="(user) => user?.id"
                :usersLoading="usersLoading"
                searchable
                clearable
              >
              </va-select>
            </div>
            <div class="md12">
              <va-input
                class="mb-4 mx-3"
                v-model="newChampionship.name"
                :label="$t('championship_name')"
                required="true"
                :rules="[(v) => v.length > 0]"
                :error-messages="requiredMessage"
              />
            </div>
            <div class="md12">
              <va-select
                class="mb-4 px-2"
                :options="champ_types"
                :label="$t('championship_type')"
                v-model="newChampionship.type_id"
                :value-by="(champ_type) => champ_type.id"
                :text-by="
                  (champ_type) =>
                    $i18n.locale == 'gb' ? champ_type.name : champ_type.name_ar
                "
                :track-by="(champ_type) => champ_type.id"
                :champTypeLoading="champTypeLoading"
                :rules="[(v) => v > 0]"
                :error-messages="requiredMessage"
              >
              </va-select>
            </div>
            <div class="md6 xs12">
              <!-- If Groups -->
              <va-select
                v-if="newChampionship.type_id == 3"
                v-model="newChampionship.teams_count"
                :options="groups_teams_count"
                :label="$t('teams_count')"
                :rules="[(v) => v > 0]"
                :error-messages="requiredMessage"
                width="50%"
                class="mx-3"
              />
              <!-- Else -->
              <va-input
                v-else
                class="mb-4 mx-3"
                v-model="newChampionship.teams_count"
                type="number"
                :label="$t('teams_count')"
                :rules="[(v) => v > 0]"
                :error-messages="requiredMessage"
              />
              <va-input
                class="mb-4 mx-3"
                v-model="newChampionship.team_players_count"
                type="number"
                :label="$t('players_count')"
                :rules="[(v) => v > 0]"
                :error-messages="requiredMessage"
              />
            </div>
            <div class="md12 mx-3 mb-4">
              <va-date-input
                :label="$t('start_time')"
                placeholder="ex: 2020/08/01"
                v-model="newChampionship.suggested_start_time"
                :style="[
                  dateError ? { border: 'solid 1px red' } : { border: '' },
                ]"
              />
              <p v-if="dateError" class="error-message">
                {{ $t("errors.invalid_date") }}
              </p>
            </div>
            <div class="md12 mb-4 mx-3">
              <va-input
                class="md12"
                v-model="newChampionship.fees"
                type="number"
                :label="$t('fees')"
                :style="[
                  feesError ? { border: 'solid 1px red' } : { border: '' },
                ]"
              />
              <p v-if="feesError" class="error-message">
                {{ $t("errors.fee_amount") }}
              </p>
            </div>

            <va-divider inset />

            <div class="card_footer">
              <va-button
                :rounded="false"
                class="mr-4"
                type="submit"
                @click="setSelectedTab(2)"
                :disabled="checkForm"
              >
                {{ $t("next") }}
                <va-icon class="material-icons">{{ arrow }}</va-icon>
              </va-button>
            </div>
          </form>
        </div>
      </va-card-content>
    </va-card>
  </form>
</template>

<script>
import { request, gql } from "graphql-request";
import { GET_ALL_USERS } from "@/graphql/queries/users";
export default {
  name: "add-champ-step-one",
  data() {
    return {
      logo: [],
      img: null,
      champ_types: [],
      users: [],
      groups_teams_count: [8, 16, 32],
      champTypeLoading: true,
      usersLoading: true,
      listValue: "Option 1",
    };
  },
  methods: {
    previewFile() {
      if (this.newChampionship.logo?.length > 0) {
        this.newChampionship.logo?.length > 1
          ? this.newChampionship.logo.splice(0, 1)
          : null;
        const file = this.newChampionship.logo[0];
        this.img = URL.createObjectURL(file);
        document.querySelector("#logo").src = this.img;
      } else {
        document.querySelector("#logo").src = "";
      }
    },
  },
  computed: {
    arrow() {
      return this.$i18n.locale == "en" || this.$i18n.locale == "gb"
        ? "chevron_right"
        : "chevron_left";
    },
    checkForm() {
      if (
        this.newChampionship.creator_id == null ||
        this.newChampionship.fees == null ||
        this.newChampionship.fees <= 0 ||
        this.newChampionship.logo == "" ||
        this.newChampionship.name == "" ||
        this.newChampionship.teams_count == "" ||
        this.newChampionship.team_players_count == "" ||
        this.newChampionship.type_id == "" ||
        this.newChampionship.suggested_start_time == null ||
        this.newChampionship.suggested_start_time < new Date()
      )
        return true;
    },
    dateError() {
      if (this.newChampionship.suggested_start_time < new Date()) {
        return true;
      }
    },
    feesError() {
      if (this.newChampionship.fees <= 0) {
        return true;
      }
    },
    logoError() {
      if (this.newChampionship.logo?.length == 0) {
        return true;
      }
    },
    requiredMessage() {
      return this.$i18n.locale == "sa"
        ? "لا يمكن أن يكون هذا الحقل فارغاً"
        : "This field can not be empty";
    },
  },
  inject: ["setSelectedTab", "newChampionship"],
  async created() {
    try {
      const CHAMPIONSHIP_TYPES = gql`
        query {
          ChampTypes {
            id
            name
            name_ar
          }
        }
      `;
      const response = await request(
        this.$store.state.appUrl,
        CHAMPIONSHIP_TYPES
      );
      this.champTypeLoading = false;
      this.champ_types = response.ChampTypes;
      const data = await request(this.$store.state.appUrl, GET_ALL_USERS);
      this.usersLoading = false;
      this.users = data.Users;
    } catch (error) {
      console.log(error);
      this.champTypeLoading = false;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if (result.value) {
            location.reload();
          }
        });
    }
  },
};
</script>

<style scoped>
.md6 {
  display: flex;
  align-items: baseline;
}
@media (max-width: 550px) {
  .xs12 {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}
.px-2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.avatar-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.avatar-container::after {
  content: "Logo";
  vertical-align: center;
  padding: 0 1rem;
  font-size: 1.2rem;
}
div.va-file-upload {
  height: 35px;
}
button.va-button {
  margin-top: 15px !important;
}
.card_footer {
  display: flex;
  justify-content: flex-end;
}
</style>
