<template>
   <div class="container mx-2">
<!-- Breadcrumb  -->
  <va-card>
      <va-card-content>
        <va-breadcrumbs color="primary">
          <va-breadcrumbs-item  :label="$t('championship')" />
          <va-breadcrumbs-item :label="$t('add_championship')" />
        </va-breadcrumbs>
     </va-card-content>
  </va-card>
  <div class="mt-4">
    <va-progress-bar @update:modelValue="progressBar" :model-value="progress">{{ selectedTab + '/3' }}</va-progress-bar>
    <va-tabs
     stateful
     grow
     v-model="selectedTab"
    >
      <template #tabs>
        <va-tab
          icon="info"
          :label="$t('champ_details')"
          name="1"
          :disabled='selectedTab != 1'
          
        />
        <va-tab
          icon="flag"
          :label="$t('menu.teams')"
          name="2"
          :disabled='selectedTab != 2'
        />
        <va-tab
          icon="sports"
          :label="$t('referees&playgrounds')"
          name="3"
          :disabled='selectedTab != 3'
        />
      </template>
      <div> 
        <div v-if="selectedTab==1">
          <add-champ-step-1/>
        </div>
        <div v-else-if="selectedTab==2">
          <add-champ-step-2/>
        </div>
        <div v-else>
          <add-champ-step-3 />
        </div>
      </div>
    </va-tabs>
  </div>
 </div>
</template>


<script>
  import addChampStep1 from './components/addChampionship-step1.vue';
  import addChampStep2 from './components/addChampionship-step2.vue';
  import addChampStep3 from './components/addChampionship-step3.vue';

  export default {
    components: {
      addChampStep1,
      addChampStep2,
      addChampStep3
    },
    data(){
      return{
        newChampionship:{creator_id:null,logo:[],name:'',teams_count:null,team_players_count:null, type_id:null,playgrounds: [],referees:[],teams :[]},
        selectedTab:1,
        progress:0
      }
    },
    provide() {
      return {
        setSelectedTab: this.setSelectedTab,
        newChampionship: this.newChampionship
      }
    },
    computed:{
      progressBar(){
        this.selectedTab == 1 ? this.progress=33.333333333333336 : this.selectedTab == 2 ? this.progress=66.66666666666667 : this.progress = 100
      }
    },
    
    methods:{
        check(selectedReferees){
            if(Object.values(selectedReferees).length<3){
                this.refErrors=this.$t('errors.referees_count')
                this.refColor="#e42222"
            }
            else{
                this.refErrors=""
                this.refColor="#039303"
            }
        },
        change(el){
          this.$store.dispatch('encodeImageFileAsURL', el)
        },
        setSelectedTab(num){
          this.selectedTab = num
          if(num==3){
            this.$store.dispatch('setSuggestedStartTime',this.newChampionship.suggested_start_time)
          }
            this.$store.dispatch('setNewChampionship',this.newChampionship)
          
        }  
    },
  }
</script>


<style>
    .md6{
      display: flex;
      align-items: baseline;
    }
    .px-2{
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .avatar-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .card_footer{
      display: flex;
      justify-content: flex-end;
      padding: 10px 30px;
    }
</style>